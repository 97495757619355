import React, { useEffect, useState } from "react"

function GetDataFormBuilder() {
  const [schema, setSchema] = useState({})
  useEffect(() => {
    const schema = {
      startDate: {
        label: "Select Start Date",
        type: "date",
        required: true,
      },
      endDate: {
        label: "Select End Date",
        type: "date",
        required: true,
      },
      interestedShown: {
        label: "Interested Shown",
        type: "boolean",
      },
      registration: {
        label: "Registration",
        type: "boolean",
      },
      nomination: {
        label: "Nomination",
        type: "boolean",
      },
    }

    setSchema(schema)
  }, [])
  return schema
}

export default GetDataFormBuilder
