import React from "react"
import { Form } from "skillstrainer-resource-library"
import GetDataFormBuilder from "../formBuilder/get-data-form-builder"
import axios from "axios"
export default function getData() {
  const handleInterestedUser = async data => {
    // if (data.interestedShown) {
    //   console.log("interestedShown====")
    // }
    // if (data.registration) {
    //   console.log("registration====")
    // }
    // if (data.nomination) {
    //   console.log("nomination====")
    // }

    const dataGet = await fetch(
      "http://localhost:1337/api/user-interests?_where[createdAt]=2022-12-16T10:17:47.652Z&_where[createdAt]=2022-12-16T10:17:47.652Z"
    )
    let getDataEventRegistrationJSON = await dataGet.json()

    // const getDataEventRegistration = await fetch(
    //   "http://localhost:1337/api/event-registrations?populate=*"
    // )
    // let getDataEventRegistrationJSON = await getDataEventRegistration.json()
    // getDataEventRegistrationJSON = getDataEventRegistrationJSON["data"]
    // console.log("json====", getDataEventRegistrationJSON)

    // const getDataEventNomination = await fetch(
    //   "http://localhost:1337/api/event-nominations?populate=*"
    // )
    // let getDataEventNominationJSON = await getDataEventNomination.json()
    // getDataEventNominationJSON = getDataEventNominationJSON["data"]
    // console.log("json====", getDataEventNominationJSON)

    // const getDataUserInterested = await fetch(
    //   "http://localhost:1337/api/user-interests?populate=*"
    // )
    // let getDataUserInterestedJSON = await getDataUserInterested.json()
    // getDataUserInterestedJSON = getDataUserInterestedJSON["data"]
    // console.log("json====", getDataUserInterestedJSON)
  }
  return (
    <>
      <div className="">Nancy</div>

      <Form
        className="ml-6 mt-2 grid grid-cols-2 gap-x-2"
        formBuilder={GetDataFormBuilder}
        submitButton={{
          text: "Get Data",
          className: "btn learn-more-btn",
        }}
        onSubmit={handleInterestedUser}
      />
    </>
  )
}
